@import "Color";
@import "Mixin";

.page#aboutCompany {
  #pageContent {
    display: flex;
    flex-direction: column;

    section {
      display: flex;

      aside {
        color: $text;

        font-family: Inter;
        font-weight: 700;
      }

      article {
        flex: 1;
        display: flex;
        flex-direction: column;
      }

      &#background {
        article {
          p {
            color: $text;

            font-family: Inter;
            font-weight: 400;
            line-height: 2;

            span {
              color: $priamry500;
            }
          }

          figure {
            display: flex;
            flex-wrap: wrap;
          }

          .refCard {
            background-color: #f3f3f3;

            .cardContent {
              height: 100%;
              display: flex;
              flex-direction: column;
              align-items: flex-end;

              p {
                width: 100%;
                text-align: left;
                line-height: 1.7;
              }

              .title {
                color: $text;

                font-family: Inter;
                font-weight: 700;
              }

              .subTitle {
                flex: 1;

                color: #444;
                font-family: Inter;
                font-weight: 400;

                span {
                  font-weight: 700;

                  &.paper {
                    color: #009a9a;
                  }
                  &.news {
                    color: $priamry500;
                  }
                }
              }

              div {
                background-color: white;
                color: black;
                @include clip-capsule();

                display: flex;
                justify-content: center;
                align-items: center;
              }
            }
          }
        }
      }

      &#missionVision {
        article {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;

          .visionCard {
            display: flex;
            justify-content: center;
            align-items: center;

            background-color: $priamry100;

            p {
              color: $text;

              font-family: Inter;
              font-weight: 500;
              line-height: 1.5;
            }
          }
        }
      }

      &#history {
        color: $text;

        article {
          display: flex;
        }

        .historyOfYear {
          display: flex;
          flex-direction: row;

          .year {
            font-variant-numeric: tabular-nums;

            font-family: Inter;
            font-weight: 700;
            line-height: 2;
          }
        }

        .historyOfMonth {
          display: flex;
          flex-direction: row;

          p,
          li {
            font-family: Inter;
          }

          .month {
            font-variant-numeric: tabular-nums;
            font-weight: 700;
          }
        }
      }

      &#members {
        article {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;

          .memberCard {
            display: flex;
            flex-direction: column;

            border: 1px solid $line;

            background-color: white;
            color: $text;

            .name {
              font-family: Inter;
              font-weight: 700;
            }

            .description {
              display: flex;
              flex-direction: column;

              p {
                font-family: Inter;
                line-height: 1.5;

                &.list {
                  &::before {
                    content: "- ";
                  }
                }
              }
            }
          }
        }
      }

      &#resource {
        article {
          .fileDownloader {
            display: flex;
            justify-content: space-between;
            align-items: center;

            border-bottom: 1px solid $line;

            &:nth-last-child(1) {
              border-bottom: none;
            }

            p {
              color: #444;
              font-family: Inter;
              font-weight: 600;
            }
          }
        }
      }
    }
  }
}
