@keyframes scroll-left {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}
header {
  position: sticky;
  top: 0;
  z-index: 1000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  border-bottom: 1px solid #d9d9d9;
}
header .leading button {
  width: max-content;
  justify-content: flex-start !important;
  align-items: flex-start !important;
}
header button {
  min-width: 0 !important;
}
header button svg {
  color: black;
}
header nav {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
header nav .dropdownBtn {
  cursor: pointer;
}
header nav .dropdownBtn .mainPage {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #444;
  text-align: center;
  border-top: 3px solid transparent;
  border-bottom: 3px solid transparent;
  font-family: Inter;
  font-weight: 700;
  height: 100%;
}
header nav .dropdownBtn .subPages {
  max-height: 0px;
  overflow: hidden;
  transition: all 0.5s ease;
  background: white;
  box-shadow: 0px 20px 30px 0px rgba(0, 0, 0, 0.25);
}
header nav .dropdownBtn .subPages button {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #444;
  background-color: white;
  border: 1px solid #d9d9d9;
  font-family: Inter;
  font-weight: 700;
}
header nav .dropdownBtn .subPages button:hover {
  border-color: #1082ff;
  background-color: #1082ff;
  color: white;
}
header nav .dropdownBtn:hover .mainPage {
  border-bottom: 3px solid #1082ff;
}
header nav .dropdownBtn:hover .subPages {
  max-height: 100vh;
}

footer {
  background-color: black;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
footer img {
  filter: brightness(0) invert(1);
}
footer section {
  width: 100%;
  display: flex;
  flex-direction: column;
}
footer section article {
  display: flex;
  flex-direction: column;
}
footer section article p {
  font-family: Inter;
  font-weight: 400;
  line-height: 1.6;
}
footer section article p#copyright {
  color: #c7c7c7;
}

#pageTitle {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
#pageTitle p {
  color: #181818;
  font-family: Inter;
  font-weight: 700;
}
#pageTitle #divider {
  height: 1px;
  background-color: #1082ff;
}

.viewMore {
  color: white;
}
.viewMore p {
  font-family: Inter;
  font-weight: 300;
  text-decoration: underline;
}

button#scrollToTop {
  position: fixed;
  aspect-ratio: 1;
  min-width: auto;
  min-height: auto;
  background: #1082ff;
  border-radius: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

#languageSwitchButton {
  display: flex;
  align-items: center;
  color: #444;
}
#languageSwitchButton .buttonWrapper {
  display: flex;
  align-items: center;
}
#languageSwitchButton .buttonWrapper button {
  color: #444;
  font-family: Inter;
}
#languageSwitchButton .buttonWrapper button.selected {
  font-weight: 700;
}
#languageSwitchButton .buttonWrapper .divider {
  width: 2px;
  background-color: #444;
}

.page#main #mainBanner {
  position: relative;
}
.page#main #mainBanner .banner {
  width: 100%;
  height: auto;
  aspect-ratio: 4/1;
}
.page#main #mainBanner #navigator {
  position: absolute;
  z-index: 100;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  color: white;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 100vh;
}
.page#main #mainBanner #navigator button {
  min-width: 0 !important;
  color: white;
}
.page#main #mainBanner #navigator p {
  color: white;
  font-family: Inter;
  font-weight: 500;
  line-height: normal;
  font-variant-numeric: tabular-nums;
}
.page#main main section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.page#main main section .sectionTitle {
  color: #1082ff;
  font-family: Gotham;
  font-weight: 900;
  line-height: 1;
}
.page#main main section#landing {
  position: relative;
  background: url("/public/Main/landing.png");
  background-size: cover;
  background-position: right;
}
.page#main main section#landing article {
  width: 100%;
  display: flex;
  flex-direction: column;
  color: black;
}
.page#main main section#landing article div {
  display: flex;
  flex-direction: column;
}
.page#main main section#landing article div p {
  font-family: Inter;
  font-weight: 700;
}
.page#main main section#landing article div #divider {
  height: 1px;
  background-color: #1082ff;
}
.page#main main section#landing article .headline {
  font-family: Gotham;
  font-weight: 700;
  line-height: 1;
  letter-spacing: -1px;
}
.page#main main section#landing article .description {
  font-family: Inter;
  font-weight: 500;
}
.page#main main section#landing .scrollDown {
  position: absolute;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #1082ff;
}
.page#main main section#landing .scrollDown p {
  font-family: Inter;
  font-weight: 700;
}
.page#main main section#steps img {
  width: 90%;
}
.page#main main section#productLineUp article {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.page#main main section#productLineUp article .productCard {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.page#main main section#productLineUp article .productCard img {
  width: 100%;
  object-fit: contain;
}
.page#main main section#productLineUp article .productCard img.product {
  width: 90%;
  object-position: center;
  aspect-ratio: 1;
}
.page#main main section#productLineUp article .productCard .thumbnail {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  aspect-ratio: 1;
}
.page#main main section#productLineUp article .productCard .thumbnail:hover .children {
  opacity: 1;
}
.page#main main section#productLineUp article .productCard .thumbnail .children {
  transition: all 0.3s ease;
  opacity: 0;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(6px);
}
.page#main main section#productLineUp article .productCard .thumbnail .children p {
  color: black;
  text-align: center;
  font-family: Inter;
}
.page#main main section#productLineUp article .productCard .thumbnail .children p span {
  font-weight: 700;
}
.page#main main section#solution article {
  width: 100%;
  background: url("/public/Main/our-solution.png");
  background-position: right bottom;
  background-repeat: no-repeat;
}
.page#main main section#solution article > div {
  width: fit-content;
  display: flex;
  flex-direction: column;
  color: black;
  backdrop-filter: blur(5px);
}
.page#main main section#solution article > div p {
  font-family: Inter;
  font-weight: 400;
}
.page#main main section#solution article > div p.headline {
  font-weight: 700;
}
.page#main main section#solution article > div .viewPDF {
  position: relative;
  width: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #1082ff;
}
.page#main main section#solution article > div .viewPDF::after {
  content: "";
  width: 100%;
  height: 1px;
  position: absolute;
  bottom: 2px;
  background-color: #1082ff;
}
.page#main main section#solution article > div .viewPDF:hover {
  color: #0067d8;
}
.page#main main section#solution article > div .viewPDF:hover::after {
  background-color: #0067d8;
}
.page#main main section#solution article > div .viewPDF p {
  font-family: Inter;
  font-weight: 600;
}
.page#main main section#technology {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}
.page#main main section#technology .card {
  overflow: hidden;
}
.page#main main section#technology .card img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.page#main main section#technology .card.tech {
  position: relative;
}
.page#main main section#technology .card.tech .side {
  perspective: 1000px;
  transform-style: preserve-3d;
  transition: all 0.5s ease;
  backface-visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.page#main main section#technology .card.tech .side.front {
  transform: rotateY(0deg);
}
.page#main main section#technology .card.tech .side.back {
  transform: rotateY(180deg);
}
.page#main main section#technology .card.tech:hover .front {
  transform: rotateY(180deg);
}
.page#main main section#technology .card.tech:hover .back {
  transform: rotateY(0deg);
}
.page#main main section#technology .card.tech .front {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  color: white;
}
.page#main main section#technology .card.tech .front p {
  font-family: Gotham;
  font-weight: 700;
  line-height: 1.15;
}
.page#main main section#technology .card.tech .front .circle {
  background-color: white;
  border-radius: 100vh;
}
.page#main main section#technology .card.tech .back {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f3f3f3;
}
.page#main main section#technology .card.tech .back p {
  color: black;
  text-align: center;
  font-family: Inter;
}
.page#main main section#technology .card.tech .back p:nth-of-type(1) {
  font-weight: 700;
  line-height: 1.3;
}
.page#main main section#technology .card.tech .back p:nth-of-type(2) {
  flex: 1;
  display: flex;
  align-items: center;
  font-weight: 400;
}
.page#main main section#technology .card.tech .back .divider {
  height: 1px;
  min-height: 1px;
  background-color: #1082ff;
}
.page#main main section#news article {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #1082ff;
}
.page#main main section#news article .sectionTitle {
  color: white;
}
.page#main main section#news article .swiper {
  width: 100%;
  --swiper-theme-color: white;
  --swiper-pagination-bottom: auto;
}
.page#main main section#news article .swiper .swiper-button-prev,
.page#main main section#news article .swiper .swiper-button-next {
  transform: scaleX(0.7);
}
.page#main main section#news article .newsSlide {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  color: white;
  background-color: aqua;
}
.page#main main section#news article .newsSlide p {
  font-family: Inter;
}
.page#main main section#news article .newsSlide p.title {
  width: 90%;
  font-weight: 500;
}
.page#main main section#news article .newsSlide p.caption {
  font-weight: 300;
}
.page#main main section#contactUs {
  background-color: #c3e0ff;
}
.page#main main section#contactUs article {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.page#main main section#contactUs article div {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.page#main main section#contactUs article div p {
  color: black;
  font-family: Inter;
  font-weight: 700;
}
.page#main main section#contactUs article div a {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 186px;
  height: 56px;
  border: 1px solid #1082ff;
  border-radius: 100vh;
  font-family: Inter;
  font-size: 16px;
  font-weight: 700;
  background-color: white;
  color: #1082ff;
}
.page#main main section#contactUs article div a:hover {
  background-color: #1082ff;
  color: white;
}
.page#main main section#contactUs article iframe,
.page#main main section#contactUs article video {
  height: 100%;
  aspect-ratio: 16/9;
}
.page#main main section#partners article.window .partnerCard {
  display: flex;
  align-items: center;
}
.page#main main section#partners article.window .partnerCard img {
  transform: scale(0.85);
}

.page#aboutCompany #pageContent {
  display: flex;
  flex-direction: column;
}
.page#aboutCompany #pageContent section {
  display: flex;
}
.page#aboutCompany #pageContent section aside {
  color: #181818;
  font-family: Inter;
  font-weight: 700;
}
.page#aboutCompany #pageContent section article {
  flex: 1;
  display: flex;
  flex-direction: column;
}
.page#aboutCompany #pageContent section#background article p {
  color: #181818;
  font-family: Inter;
  font-weight: 400;
  line-height: 2;
}
.page#aboutCompany #pageContent section#background article p span {
  color: #1082ff;
}
.page#aboutCompany #pageContent section#background article figure {
  display: flex;
  flex-wrap: wrap;
}
.page#aboutCompany #pageContent section#background article .refCard {
  background-color: #f3f3f3;
}
.page#aboutCompany #pageContent section#background article .refCard .cardContent {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.page#aboutCompany #pageContent section#background article .refCard .cardContent p {
  width: 100%;
  text-align: left;
  line-height: 1.7;
}
.page#aboutCompany #pageContent section#background article .refCard .cardContent .title {
  color: #181818;
  font-family: Inter;
  font-weight: 700;
}
.page#aboutCompany #pageContent section#background article .refCard .cardContent .subTitle {
  flex: 1;
  color: #444;
  font-family: Inter;
  font-weight: 400;
}
.page#aboutCompany #pageContent section#background article .refCard .cardContent .subTitle span {
  font-weight: 700;
}
.page#aboutCompany #pageContent section#background article .refCard .cardContent .subTitle span.paper {
  color: #009a9a;
}
.page#aboutCompany #pageContent section#background article .refCard .cardContent .subTitle span.news {
  color: #1082ff;
}
.page#aboutCompany #pageContent section#background article .refCard .cardContent div {
  background-color: white;
  color: black;
  border-radius: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.page#aboutCompany #pageContent section#missionVision article {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.page#aboutCompany #pageContent section#missionVision article .visionCard {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #e7f2ff;
}
.page#aboutCompany #pageContent section#missionVision article .visionCard p {
  color: #181818;
  font-family: Inter;
  font-weight: 500;
  line-height: 1.5;
}
.page#aboutCompany #pageContent section#history {
  color: #181818;
}
.page#aboutCompany #pageContent section#history article {
  display: flex;
}
.page#aboutCompany #pageContent section#history .historyOfYear {
  display: flex;
  flex-direction: row;
}
.page#aboutCompany #pageContent section#history .historyOfYear .year {
  font-variant-numeric: tabular-nums;
  font-family: Inter;
  font-weight: 700;
  line-height: 2;
}
.page#aboutCompany #pageContent section#history .historyOfMonth {
  display: flex;
  flex-direction: row;
}
.page#aboutCompany #pageContent section#history .historyOfMonth p,
.page#aboutCompany #pageContent section#history .historyOfMonth li {
  font-family: Inter;
}
.page#aboutCompany #pageContent section#history .historyOfMonth .month {
  font-variant-numeric: tabular-nums;
  font-weight: 700;
}
.page#aboutCompany #pageContent section#members article {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.page#aboutCompany #pageContent section#members article .memberCard {
  display: flex;
  flex-direction: column;
  border: 1px solid #d9d9d9;
  background-color: white;
  color: #181818;
}
.page#aboutCompany #pageContent section#members article .memberCard .name {
  font-family: Inter;
  font-weight: 700;
}
.page#aboutCompany #pageContent section#members article .memberCard .description {
  display: flex;
  flex-direction: column;
}
.page#aboutCompany #pageContent section#members article .memberCard .description p {
  font-family: Inter;
  line-height: 1.5;
}
.page#aboutCompany #pageContent section#members article .memberCard .description p.list::before {
  content: "- ";
}
.page#aboutCompany #pageContent section#resource article .fileDownloader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #d9d9d9;
}
.page#aboutCompany #pageContent section#resource article .fileDownloader:nth-last-child(1) {
  border-bottom: none;
}
.page#aboutCompany #pageContent section#resource article .fileDownloader p {
  color: #444;
  font-family: Inter;
  font-weight: 600;
}

.page#aboutUsage #pageContent {
  display: flex;
  flex-direction: column;
}
.page#aboutUsage #pageContent #banners .usageBanner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: white;
}
.page#aboutUsage #pageContent #banners .usageBanner .title {
  font-family: Gotham;
  font-weight: 900;
}
.page#aboutUsage #pageContent #banners .usageBanner .descriptionWrapper {
  display: flex;
  flex-direction: column;
}
.page#aboutUsage #pageContent #banners .usageBanner .descriptionWrapper p {
  font-family: Inter;
  font-weight: 300;
  line-height: 1.5;
}
.page#aboutUsage #pageContent #banners .usageBanner .hashtagWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
}
.page#aboutUsage #pageContent #banners .usageBanner .hashtagWrapper p {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100vh;
  background-color: #d9d9d9;
  color: #444;
  font-family: Inter;
  font-weight: 700;
}
.page#aboutUsage #pageContent #poc {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.page#aboutUsage #pageContent #poc #bar {
  width: 1px;
  background-color: #1082ff;
}
.page#aboutUsage #pageContent #poc article.title {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.page#aboutUsage #pageContent #poc article.title p {
  font-family: Inter;
  text-align: center;
}
.page#aboutUsage #pageContent #poc article.title p:nth-of-type(1) {
  color: #1082ff;
  font-weight: 700;
}
.page#aboutUsage #pageContent #poc article.title p:nth-of-type(2) {
  color: #181818;
  font-weight: 400;
}
.page#aboutUsage #pageContent #poc article.window .pocCard {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.page#aboutUsage #pageContent #poc article.window .pocCard img {
  object-fit: cover;
}
.page#aboutUsage #pageContent #poc article.window .pocCard p {
  color: #181818;
  font-family: Inter;
  font-weight: 500;
}
.page#aboutUsage #pageContent #inquiry {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #ddd;
}
.page#aboutUsage #pageContent #inquiry div {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.page#aboutUsage #pageContent #inquiry div p {
  color: #181818;
  font-family: Inter;
  text-align: center;
}
.page#aboutUsage #pageContent #inquiry div p:nth-of-type(1) {
  font-weight: 700;
}
.page#aboutUsage #pageContent #inquiry div p:nth-of-type(2) {
  font-weight: 500;
}

.page#aboutNews #pageContent section#newsCardGroup {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.page#aboutNews #pageContent section#newsCardGroup .newsCard {
  transition: all 0.5s ease;
  overflow: hidden;
  border: 1px solid #444;
  background-color: #444;
  box-shadow: none;
}
.page#aboutNews #pageContent section#newsCardGroup .newsCard:hover {
  border-color: #1082ff;
  background-color: #1082ff;
  box-shadow: 0px 0px 20px 0px rgba(16, 130, 255, 0.35);
}
.page#aboutNews #pageContent section#newsCardGroup .newsCard .cardContent {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.page#aboutNews #pageContent section#newsCardGroup .newsCard .cardContent figure {
  width: 100%;
  aspect-ratio: 5/4;
  overflow: hidden;
}
.page#aboutNews #pageContent section#newsCardGroup .newsCard .cardContent figure img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transform: scale(1.01);
}
.page#aboutNews #pageContent section#newsCardGroup .newsCard .cardContent > div {
  flex: 1;
  color: white;
  display: flex;
  flex-direction: column;
}
.page#aboutNews #pageContent section#newsCardGroup .newsCard .cardContent > div p {
  flex: 1;
  font-family: Inter;
  font-weight: 400;
  line-height: 1.6;
}

.productCommon#banner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url(/public/mainCover_bg.jpeg) no-repeat center center;
  background-size: cover;
}
.productCommon#banner p {
  color: white;
  font-family: Inter;
  font-weight: 700;
}
.productCommon#info {
  display: flex;
  flex-direction: column;
}
.productCommon#info .headline {
  display: flex;
  flex-direction: column;
}
.productCommon#info .headline img.logo {
  object-fit: contain;
  object-position: left;
}
.productCommon#info .headline .divider {
  height: 2px;
  background-color: #1082ff;
}
.productCommon#info .headline .description {
  color: #939393;
  font-family: Inter;
  font-weight: 600;
}
.productCommon#info article {
  display: flex;
}
.productCommon#info article .productWrapper {
  width: 30%;
}
.productCommon#info article .productWrapper img {
  width: 100%;
  object-fit: contain;
}
.productCommon#info article div {
  display: flex;
  flex-direction: column;
}
.productCommon#info article div.children {
  flex: 1;
}
.productCommon#info article div p {
  color: #939393;
  font-family: Inter;
}
.productCommon#info article div p span {
  color: #1082ff;
  font-weight: 700;
}
.productCommon.pointHeadline {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.productCommon.pointHeadline .chip {
  background-color: #1082ff;
  color: white;
  border-radius: 100vh;
  font-family: Inter;
  font-weight: 700;
}
.productCommon.pointHeadline .title {
  color: black;
  text-align: center;
  font-family: Inter;
  font-weight: 700;
}
.productCommon#viewMore {
  display: flex;
  justify-content: space-between;
  background-color: #c3e0ff;
}
.productCommon#viewMore .general {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.productCommon#viewMore .general p {
  color: black;
  font-family: Inter;
  font-weight: 700;
}
.productCommon#viewMore .cardWrapper {
  display: flex;
}
.productCommon#viewMore .cardWrapper .viewMoreCard {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  aspect-ratio: 1;
}
.productCommon#viewMore .cardWrapper .viewMoreCard img {
  object-fit: contain;
}
.productCommon#viewMore .cardWrapper .viewMoreCard img.product {
  width: 90%;
  aspect-ratio: 1;
}

#medistep section,
#medistepM section {
  overflow: hidden;
}
#medistep section.points,
#medistepM section.points {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
#medistep section.points .point,
#medistepM section.points .point {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
#medistep section.points .point:nth-child(1) div,
#medistepM section.points .point:nth-child(1) div {
  width: 100%;
  display: flex;
  align-items: center;
}
#medistep section.points .point:nth-child(1) div article,
#medistepM section.points .point:nth-child(1) div article {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
#medistep section.points .point:nth-child(1) div article > *,
#medistepM section.points .point:nth-child(1) div article > * {
  width: 100%;
  object-fit: contain;
}
#medistep section.points .point:nth-child(2) img,
#medistepM section.points .point:nth-child(2) img {
  object-fit: contain;
}
#medistep section.points .point:nth-child(3) img,
#medistepM section.points .point:nth-child(3) img {
  object-fit: contain;
}

@media (min-width: 0px) {
  ul {
    padding-inline-start: 40px;
  }
  #languageSwitchButton {
    gap: 12px;
  }
  #languageSwitchButton .buttonWrapper {
    gap: 12px;
  }
  #languageSwitchButton .buttonWrapper button {
    font-size: 20px;
  }
  #languageSwitchButton .divider {
    height: 20px;
  }
  header {
    height: 80px;
    padding: 0 5vw;
  }
  header .leading button img {
    height: 24px;
  }
  header .leading button svg {
    height: 24px;
    width: 24px;
  }
  header aside {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.4);
    display: flex;
    flex-direction: column;
  }
  header aside .content {
    width: 100%;
    height: min-content;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding: 24px 24px 32px 24px;
    gap: 12px;
  }
  header aside .content button {
    min-width: 0;
  }
  header aside .content button svg {
    color: black;
    height: 24px;
    width: 24px;
  }
  header aside .content nav {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
  header aside .content nav .pageList {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 12px 0;
    border-bottom: 1px solid #d9d9d9;
  }
  header aside .content nav .pageList .mainPage {
    padding: 0 8px;
    color: black;
    font-family: Inter;
    font-size: 24px;
    font-weight: 700;
  }
  header aside .content nav .pageList .subPages {
    padding-left: 24px;
  }
  header aside .content nav .pageList .subPages button {
    width: 100%;
    padding: 12px 0px;
    display: flex;
    justify-content: space-between;
  }
  header aside .content nav .pageList .subPages button p {
    color: black;
    font-family: Inter;
    font-size: 18px;
    font-weight: 600;
  }
  header aside .content nav .pageList .subPages button svg {
    color: #939393;
    font-size: 18px;
  }
  header aside .backdrop {
    flex: 1;
    width: 100%;
  }
  footer {
    gap: 24px;
    padding: 48px 5vw 56px 5vw;
  }
  footer img {
    height: 20px;
  }
  footer section {
    gap: 16px;
  }
  footer section article {
    gap: 4px;
  }
  footer section article p {
    font-size: 16px;
  }
  footer section article p#copyright {
    font-size: 14px;
  }
  #pageTitle {
    padding: 76.8px 0px 83.2px 0px;
    gap: 54px;
  }
  #pageTitle p {
    font-size: 32px;
  }
  #pageTitle #divider {
    width: 80px;
  }
  .viewMore {
    gap: 4px;
  }
  .viewMore svg {
    width: 16px;
    height: 16px;
  }
  .viewMore p {
    font-size: 14px;
  }
  button#scrollToTop {
    bottom: 32px;
    right: 24px;
    width: 56px;
    height: 56px;
  }
  button#scrollToTop svg {
    width: 32px;
  }
  .page#main #mainBanner #navigator {
    bottom: 16px;
    right: 5vw;
    height: 36px;
    padding: 0 8px;
    gap: 4px;
  }
  .page#main #mainBanner #navigator button {
    padding: 4px 8px;
  }
  .page#main #mainBanner #navigator p {
    font-size: 15px;
  }
  .page#main main section .sectionTitle {
    font-size: 24px;
  }
  .page#main main section#landing {
    height: 580px;
    background-position: left;
  }
  .page#main main section#landing article {
    gap: 40px;
    padding: 0 5vw;
  }
  .page#main main section#landing article div {
    gap: 16px;
  }
  .page#main main section#landing article div p {
    font-size: 22px;
  }
  .page#main main section#landing article div #divider {
    width: 120px;
  }
  .page#main main section#landing article .headline {
    font-size: 52px;
  }
  .page#main main section#landing article .description {
    font-size: 16px;
  }
  .page#main main section#landing .scrollDown {
    bottom: 4px;
    gap: 12px;
    padding: 4px;
  }
  .page#main main section#landing .scrollDown:hover {
    animation: spring-pop 0.2s linear;
    transform: translateY(10px);
  }
  @keyframes spring-pop {
    0% {
      transform: translateY(0);
    }
    60% {
      transform: translateY(10px);
    }
    70% {
      transform: translateY(calc(1.5 * 10px));
    }
    100% {
      transform: translateY(calc(0.75 * 10px));
    }
  }
  .page#main main section#landing .scrollDown img {
    width: 24px;
  }
  .page#main main section#landing .scrollDown p {
    font-size: 20px;
  }
  .page#main main section#steps {
    padding: 64px 5vw;
  }
  .page#main main section#productLineUp {
    padding: 64px 5vw;
    gap: 72px;
  }
  .page#main main section#productLineUp article {
    gap: 48px 64px;
  }
  .page#main main section#productLineUp article .productCard {
    width: 280px;
    gap: 20px;
  }
  .page#main main section#productLineUp article .productCard img.logo {
    height: 32px;
  }
  .page#main main section#productLineUp article .productCard .thumbnail p {
    font-size: 16px;
  }
  .page#main main section#productLineUp article button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 178px;
    height: 54px;
    border: 1px solid #1082ff;
    border-radius: 100vh;
    font-family: Inter;
    font-size: 16px;
    font-weight: 700;
    background-color: white;
    color: #1082ff;
  }
  .page#main main section#productLineUp article button:hover {
    background-color: #1082ff;
    color: white;
  }
  .page#main main section#solution {
    gap: 72px;
    padding: 64px 5vw;
  }
  .page#main main section#solution article {
    min-height: 480px;
    background-size: auto 280px;
    padding-bottom: 168px;
  }
  .page#main main section#solution article > div {
    gap: 30px;
    padding-right: 16px;
    backdrop-filter: blur(5px);
  }
  .page#main main section#solution article > div p {
    font-size: 16px;
  }
  .page#main main section#solution article > div p.headline {
    font-size: 32px;
  }
  .page#main main section#solution article > div .viewPDF {
    margin-top: 4px;
    gap: 4px;
  }
  .page#main main section#solution article > div .viewPDF p {
    font-size: 16px;
  }
  .page#main main section#solution article > div .viewPDF svg {
    width: 16px;
  }
  .page#main main section#technology {
    padding: 64px 5vw;
    gap: 72px 80px;
  }
  .page#main main section#technology .card {
    width: 280px;
    height: 380px;
    border-radius: 10px;
  }
  .page#main main section#technology .card.tech .front {
    gap: 16px;
    padding: 28px 32px;
  }
  .page#main main section#technology .card.tech .front p {
    font-size: 26px;
  }
  .page#main main section#technology .card.tech .front .circle {
    min-width: 20px;
    min-height: 20px;
  }
  .page#main main section#technology .card.tech .back {
    padding: 40px;
    gap: 28px;
  }
  .page#main main section#technology .card.tech .back p:nth-of-type(1) {
    font-size: 24px;
  }
  .page#main main section#technology .card.tech .back p:nth-of-type(2) {
    font-size: 16px;
  }
  .page#main main section#technology .card.tech .back .divider {
    width: 80px;
  }
  .page#main main section#news {
    padding: 64px 5vw;
    gap: 72px;
  }
  .page#main main section#news article {
    gap: 44px;
    padding: 50px 20px 0 20px;
    border-radius: 30px;
  }
  .page#main main section#news article .swiper {
    height: 256px;
    --swiper-navigation-size: 16px;
    --swiper-pagination-top: 24px;
  }
  .page#main main section#news article .swiper .swiper-button-prev,
  .page#main main section#news article .swiper .swiper-button-next {
    margin: 0 0px;
  }
  .page#main main section#news article .newsSlide {
    gap: 12px;
    padding: 20px 32px;
  }
  .page#main main section#news article .newsSlide p.title {
    font-size: 16px;
  }
  .page#main main section#news article .newsSlide p.caption {
    display: none;
    font-size: 16px;
  }
  .page#main main section#contactUs {
    padding: 64px 5vw;
    gap: 72px;
  }
  .page#main main section#contactUs article {
    height: auto;
    flex-direction: column;
    gap: 56px;
  }
  .page#main main section#contactUs article div {
    gap: 24px;
  }
  .page#main main section#contactUs article div p {
    font-size: 32px;
  }
  .page#main main section#contactUs article div a {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 186px;
    height: 56px;
    border: 1px solid #1082ff;
    border-radius: 100vh;
    font-family: Inter;
    font-size: 16px;
    font-weight: 700;
    background-color: white;
    color: #1082ff;
  }
  .page#main main section#contactUs article div a:hover {
    background-color: #1082ff;
    color: white;
  }
  .page#main main section#contactUs article iframe,
  .page#main main section#contactUs article video {
    height: auto;
    width: 100%;
  }
  .page#main main section#partners {
    padding: 64px 5vw;
    gap: 72px;
  }
  .page#main main section#partners article.window {
    width: 100%;
    overflow: hidden;
  }
  .page#main main section#partners article.window .content,
  .page#main main section#partners article.window .tail {
    width: max-content;
    overflow: visible;
    display: flex;
    gap: 44px;
  }
  .page#main main section#partners article.window .content {
    position: relative;
    padding-right: 44px;
    animation: scroll-left 40s linear infinite;
  }
  .page#main main section#partners article.window .tail {
    position: absolute;
    left: 100%;
  }
  .productCommon#banner {
    height: 360px;
    gap: 32px;
    padding: 0 5vw;
  }
  .productCommon#banner p {
    font-size: 24px;
  }
  .productCommon#banner a {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 154px;
    height: 52px;
    border: 1px solid #1082ff;
    border-radius: 100vh;
    font-family: Inter;
    font-size: 16px;
    font-weight: 700;
    background-color: white;
    color: #1082ff;
    border-color: white;
    background-color: gray;
    color: white;
  }
  .productCommon#banner a:hover {
    background-color: #1082ff;
    color: white;
  }
  .productCommon#banner a:hover {
    background-color: white;
    color: black;
  }
  .productCommon#info {
    gap: 44px;
    padding: 64px 5vw;
  }
  .productCommon#info .headline {
    gap: 20px;
  }
  .productCommon#info .headline img.logo {
    height: 40px;
  }
  .productCommon#info .headline .divider {
    width: 150px;
  }
  .productCommon#info .headline .description {
    font-size: 20px;
  }
  .productCommon#info article {
    flex-direction: column;
    align-items: center;
    gap: 48px;
  }
  .productCommon#info article .productWrapper {
    width: 80%;
  }
  .productCommon#info article .productWrapper img {
    width: 100%;
    object-fit: contain;
  }
  .productCommon#info article div {
    gap: 24px;
  }
  .productCommon#info article div p {
    font-size: 16px;
  }
  .productCommon.pointHeadline {
    gap: 20px;
  }
  .productCommon.pointHeadline .chip {
    padding: 4px 18px;
    font-size: 20px;
  }
  .productCommon.pointHeadline .title {
    font-size: 32px;
  }
  .productCommon#viewMore {
    flex-direction: column;
    gap: 56px;
    padding: 64px 5vw;
  }
  .productCommon#viewMore .general {
    gap: 100px;
  }
  .productCommon#viewMore .general p {
    font-size: 32px;
  }
  .productCommon#viewMore .cardWrapper {
    gap: 24px;
    flex-direction: column;
    align-items: flex-end;
  }
  .productCommon#viewMore .cardWrapper .viewMoreCard {
    border-radius: 12px;
    width: 260px;
    padding: 32px 32px 20px 32px;
    gap: 28px;
  }
  .productCommon#viewMore .cardWrapper .viewMoreCard img.logo {
    height: 32px;
  }
  .productCommon#viewMore .cardWrapper .viewMoreCard button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 152px;
    height: 48px;
    border: 1px solid #1082ff;
    border-radius: 100vh;
    font-family: Inter;
    font-size: 16px;
    font-weight: 700;
    background-color: white;
    color: #1082ff;
    font-size: 14px;
  }
  .productCommon#viewMore .cardWrapper .viewMoreCard button:hover {
    background-color: #1082ff;
    color: white;
  }
  #medistep section.points,
  #medistepM section.points {
    padding: 64px 5vw;
    gap: 80px;
  }
  #medistep section.points .point,
  #medistepM section.points .point {
    gap: 64px;
  }
  #medistep section.points .point:nth-child(1) div.content,
  #medistepM section.points .point:nth-child(1) div.content {
    flex-direction: column;
    gap: 24px;
  }
  #medistep section.points .point:nth-child(2) img,
  #medistepM section.points .point:nth-child(2) img {
    height: 254px;
  }
  #medistep section.points .point:nth-child(3) img,
  #medistepM section.points .point:nth-child(3) img {
    height: 420px;
  }
  .page#aboutCompany #pageContent {
    padding: 0px 5vw 64px 5vw;
    gap: 72px;
  }
  .page#aboutCompany #pageContent section {
    flex-direction: column;
    gap: 16px;
  }
  .page#aboutCompany #pageContent section aside {
    width: 280px;
    font-size: 24px;
  }
  .page#aboutCompany #pageContent section#background article {
    gap: 20px;
  }
  .page#aboutCompany #pageContent section#background article p {
    font-size: 18px;
  }
  .page#aboutCompany #pageContent section#background article figure {
    justify-content: center;
    gap: 24px;
    padding: 24px 0;
  }
  .page#aboutCompany #pageContent section#background article .refCard {
    width: 280px;
    min-height: 280px;
  }
  .page#aboutCompany #pageContent section#background article .refCard .cardContent {
    padding: 24px 24px;
    gap: 16px;
  }
  .page#aboutCompany #pageContent section#background article .refCard .cardContent .title {
    font-size: 20px;
  }
  .page#aboutCompany #pageContent section#background article .refCard .cardContent .subTitle {
    font-size: 17px;
  }
  .page#aboutCompany #pageContent section#background article .refCard .cardContent div {
    width: 44px;
    height: 44px;
  }
  .page#aboutCompany #pageContent section#background article .refCard .cardContent div svg {
    width: 18px;
  }
  .page#aboutCompany #pageContent section#missionVision article {
    gap: 25px;
  }
  .page#aboutCompany #pageContent section#missionVision article .visionCard {
    padding: 32px 24px;
    width: 480px;
    min-height: 140px;
  }
  .page#aboutCompany #pageContent section#missionVision article .visionCard p {
    font-size: 17px;
  }
  .page#aboutCompany #pageContent section#history article {
    gap: 48px;
  }
  .page#aboutCompany #pageContent section#history .historyOfYear {
    gap: 48px;
  }
  .page#aboutCompany #pageContent section#history .historyOfYear .year {
    font-size: 20px;
    line-height: 40px;
  }
  .page#aboutCompany #pageContent section#history .historyOfMonth {
    gap: 14px;
  }
  .page#aboutCompany #pageContent section#history .historyOfMonth p,
  .page#aboutCompany #pageContent section#history .historyOfMonth li {
    font-size: 18px;
    line-height: 40px;
  }
  .page#aboutCompany #pageContent section#members article {
    justify-content: center;
    gap: 32px 36px;
  }
  .page#aboutCompany #pageContent section#members article .memberCard {
    width: 320px;
    min-height: 300px;
    padding: 28px 30px;
    gap: 14px;
  }
  .page#aboutCompany #pageContent section#members article .memberCard .name {
    font-size: 20px;
  }
  .page#aboutCompany #pageContent section#members article .memberCard .description {
    gap: 4px;
  }
  .page#aboutCompany #pageContent section#members article .memberCard .description p {
    font-size: 18px;
  }
  .page#aboutCompany #pageContent section#resource article .fileDownloader {
    gap: 24px;
    padding: 16px 0;
  }
  .page#aboutCompany #pageContent section#resource article .fileDownloader p {
    font-size: 18px;
  }
  .page#aboutCompany #pageContent section#resource article .fileDownloader button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 150px;
    height: 48px;
    border: 1px solid #1082ff;
    border-radius: 100vh;
    font-family: Inter;
    font-size: 16px;
    font-weight: 700;
    background-color: white;
    color: #1082ff;
    min-width: 150px;
    min-height: 48px;
  }
  .page#aboutCompany #pageContent section#resource article .fileDownloader button:hover {
    background-color: #1082ff;
    color: white;
  }
  .page#aboutUsage #pageContent #banners .usageBanner {
    padding: 80px 5vw;
    gap: 24px;
  }
  .page#aboutUsage #pageContent #banners .usageBanner .title {
    font-size: 32px;
  }
  .page#aboutUsage #pageContent #banners .usageBanner .descriptionWrapper {
    gap: 16px;
  }
  .page#aboutUsage #pageContent #banners .usageBanner .descriptionWrapper p {
    font-size: 18px;
  }
  .page#aboutUsage #pageContent #banners .usageBanner .hashtagWrapper {
    margin-top: 20px;
    gap: 16px;
  }
  .page#aboutUsage #pageContent #banners .usageBanner .hashtagWrapper p {
    padding: 8px 20px;
    font-size: 14px;
  }
  .page#aboutUsage #pageContent #poc {
    gap: 54px;
    padding: 64px 5vw;
  }
  .page#aboutUsage #pageContent #poc #bar {
    height: 72px;
  }
  .page#aboutUsage #pageContent #poc article.title {
    gap: 10px;
  }
  .page#aboutUsage #pageContent #poc article.title p:nth-of-type(1) {
    font-size: 32px;
  }
  .page#aboutUsage #pageContent #poc article.title p:nth-of-type(2) {
    font-size: 20px;
  }
  .page#aboutUsage #pageContent #poc article.window {
    width: 100%;
    overflow: hidden;
  }
  .page#aboutUsage #pageContent #poc article.window .content,
  .page#aboutUsage #pageContent #poc article.window .tail {
    width: max-content;
    overflow: visible;
    display: flex;
    gap: 50px;
  }
  .page#aboutUsage #pageContent #poc article.window .content {
    position: relative;
    padding-right: 50px;
    animation: scroll-left 60s linear infinite;
  }
  .page#aboutUsage #pageContent #poc article.window .tail {
    position: absolute;
    left: 100%;
  }
  .page#aboutUsage #pageContent #poc article.window .pocCard {
    gap: 12px;
  }
  .page#aboutUsage #pageContent #poc article.window .pocCard img {
    width: 400px;
    height: 400px;
  }
  .page#aboutUsage #pageContent #poc article.window .pocCard p {
    font-size: 14px;
  }
  .page#aboutUsage #pageContent #inquiry {
    gap: 48px;
    padding: 64px 5vw;
  }
  .page#aboutUsage #pageContent #inquiry div {
    gap: 24px;
  }
  .page#aboutUsage #pageContent #inquiry div p:nth-of-type(1) {
    font-size: 32px;
  }
  .page#aboutUsage #pageContent #inquiry div p:nth-of-type(2) {
    font-size: 18px;
  }
  .page#aboutUsage #pageContent #inquiry a {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 200px;
    height: 56px;
    border: 1px solid #1082ff;
    border-radius: 100vh;
    font-family: Inter;
    font-size: 16px;
    font-weight: 700;
    background-color: white;
    color: #1082ff;
  }
  .page#aboutUsage #pageContent #inquiry a:hover {
    background-color: #1082ff;
    color: white;
  }
  .page#aboutNews #pageContent section#newsCardGroup {
    gap: 48px 56px;
    padding: 0 5vw 64px 5vw;
  }
  .page#aboutNews #pageContent section#newsCardGroup .newsCard {
    width: 300px;
    min-height: 380px;
    border-radius: 10px;
  }
  .page#aboutNews #pageContent section#newsCardGroup .newsCard:hover {
    transform: translateY(-16px);
  }
  .page#aboutNews #pageContent section#newsCardGroup .newsCard .cardContent > div {
    padding: 20px 32px;
    gap: 20px;
  }
  .page#aboutNews #pageContent section#newsCardGroup .newsCard .cardContent > div p {
    font-size: 16px;
  }
}
@media (min-width: 769px) {
  ul {
    padding-inline-start: 40px;
  }
  #languageSwitchButton {
    gap: 12px;
  }
  #languageSwitchButton .buttonWrapper {
    gap: 12px;
  }
  #languageSwitchButton .buttonWrapper button {
    font-size: 20px;
  }
  #languageSwitchButton .divider {
    height: 20px;
  }
  header {
    height: 80px;
    padding: 0 max(138px, 10vw);
  }
  header .leading button img {
    height: 24px;
  }
  header nav {
    gap: 24px;
  }
  header nav .dropdownBtn {
    height: 80px;
  }
  header nav .dropdownBtn .mainPage {
    font-size: 20px;
  }
  header nav .dropdownBtn .subPages button {
    font-size: 18px;
    padding: 16px 20px;
  }
  footer {
    gap: 24px;
    padding: 56px max(138px, 10vw) 64px max(138px, 10vw);
  }
  footer img {
    height: 20px;
  }
  footer section {
    gap: 16px;
  }
  footer section article {
    gap: 4px;
  }
  footer section article p {
    font-size: 16px;
  }
  footer section article p#copyright {
    font-size: 14px;
  }
  #pageTitle {
    padding: 89.6px 0px 83.2px 0px;
    gap: 54px;
  }
  #pageTitle p {
    font-size: 32px;
  }
  #pageTitle #divider {
    width: 80px;
  }
  .viewMore {
    gap: 4px;
  }
  .viewMore svg {
    width: 16px;
    height: 16px;
  }
  .viewMore p {
    font-size: 14px;
  }
  button#scrollToTop {
    bottom: 48px;
    right: 48px;
    width: 56px;
    height: 56px;
  }
  button#scrollToTop svg {
    width: 32px;
  }
  .page#main #mainBanner #navigator {
    bottom: 32px;
    right: max(138px, 10vw);
    height: 44px;
    padding: 0 12px;
    gap: 8px;
  }
  .page#main #mainBanner #navigator button {
    padding: 4px 8px;
  }
  .page#main #mainBanner #navigator p {
    font-size: 18px;
  }
  .page#main main section .sectionTitle {
    font-size: 24px;
  }
  .page#main main section#landing {
    height: 540px;
    background-position: right;
  }
  .page#main main section#landing article {
    gap: 40px;
    padding: 0 max(138px, 10vw);
  }
  .page#main main section#landing article div {
    gap: 16px;
  }
  .page#main main section#landing article div p {
    font-size: 22px;
  }
  .page#main main section#landing article div #divider {
    width: 120px;
  }
  .page#main main section#landing article .headline {
    font-size: 72px;
  }
  .page#main main section#landing article .description {
    font-size: 18px;
  }
  .page#main main section#landing .scrollDown {
    bottom: 4px;
    gap: 12px;
    padding: 4px;
  }
  .page#main main section#landing .scrollDown:hover {
    animation: spring-pop 0.2s linear;
    transform: translateY(10px);
  }
  @keyframes spring-pop {
    0% {
      transform: translateY(0);
    }
    60% {
      transform: translateY(10px);
    }
    70% {
      transform: translateY(calc(1.5 * 10px));
    }
    100% {
      transform: translateY(calc(0.75 * 10px));
    }
  }
  .page#main main section#landing .scrollDown img {
    width: 24px;
  }
  .page#main main section#landing .scrollDown p {
    font-size: 20px;
  }
  .page#main main section#steps {
    padding: 64px max(138px, 10vw);
  }
  .page#main main section#productLineUp {
    padding: 64px max(138px, 10vw);
    gap: 72px;
  }
  .page#main main section#productLineUp article {
    gap: 48px 64px;
  }
  .page#main main section#productLineUp article .productCard {
    width: 320px;
    gap: 32px;
  }
  .page#main main section#productLineUp article .productCard img.logo {
    height: 32px;
  }
  .page#main main section#productLineUp article .productCard .thumbnail p {
    font-size: 16px;
  }
  .page#main main section#productLineUp article button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 186px;
    height: 60px;
    border: 1px solid #1082ff;
    border-radius: 100vh;
    font-family: Inter;
    font-size: 16px;
    font-weight: 700;
    background-color: white;
    color: #1082ff;
  }
  .page#main main section#productLineUp article button:hover {
    background-color: #1082ff;
    color: white;
  }
  .page#main main section#solution {
    gap: 72px;
    padding: 64px max(138px, 10vw);
  }
  .page#main main section#solution article {
    min-height: 480px;
    background-size: auto 540px;
    padding-bottom: 150px;
  }
  .page#main main section#solution article > div {
    gap: 30px;
    padding-right: 16px;
    backdrop-filter: blur(5px);
  }
  .page#main main section#solution article > div p {
    font-size: 16px;
  }
  .page#main main section#solution article > div p.headline {
    font-size: 32px;
  }
  .page#main main section#solution article > div .viewPDF {
    margin-top: 4px;
    gap: 4px;
  }
  .page#main main section#solution article > div .viewPDF p {
    font-size: 16px;
  }
  .page#main main section#solution article > div .viewPDF svg {
    width: 16px;
  }
  .page#main main section#technology {
    padding: 64px max(138px, 10vw);
    gap: 72px 80px;
  }
  .page#main main section#technology .card {
    width: 280px;
    height: 380px;
    border-radius: 10px;
  }
  .page#main main section#technology .card.tech .front {
    gap: 16px;
    padding: 28px 32px;
  }
  .page#main main section#technology .card.tech .front p {
    font-size: 26px;
  }
  .page#main main section#technology .card.tech .front .circle {
    min-width: 20px;
    min-height: 20px;
  }
  .page#main main section#technology .card.tech .back {
    padding: 40px;
    gap: 28px;
  }
  .page#main main section#technology .card.tech .back p:nth-of-type(1) {
    font-size: 24px;
  }
  .page#main main section#technology .card.tech .back p:nth-of-type(2) {
    font-size: 16px;
  }
  .page#main main section#technology .card.tech .back .divider {
    width: 80px;
  }
  .page#main main section#news {
    padding: 64px max(138px, 10vw);
    gap: 72px;
  }
  .page#main main section#news article {
    gap: 44px;
    padding: 50px 120px 0 120px;
    border-radius: 30px;
  }
  .page#main main section#news article .swiper {
    height: 472px;
    --swiper-navigation-size: 24px;
    --swiper-pagination-top: 32px;
  }
  .page#main main section#news article .swiper .swiper-button-prev,
  .page#main main section#news article .swiper .swiper-button-next {
    margin: 0 24px;
  }
  .page#main main section#news article .newsSlide {
    gap: 12px;
    padding: 48px 84px;
  }
  .page#main main section#news article .newsSlide p.title {
    font-size: 22px;
  }
  .page#main main section#news article .newsSlide p.caption {
    display: block;
    font-size: 16px;
  }
  .page#main main section#contactUs {
    padding: 64px max(138px, 10vw);
    gap: 72px;
  }
  .page#main main section#contactUs article {
    height: 300px;
    flex-direction: row;
    gap: 24px;
  }
  .page#main main section#contactUs article div {
    gap: 36px;
  }
  .page#main main section#contactUs article div p {
    font-size: 32px;
  }
  .page#main main section#contactUs article div a {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 186px;
    height: 56px;
    border: 1px solid #1082ff;
    border-radius: 100vh;
    font-family: Inter;
    font-size: 16px;
    font-weight: 700;
    background-color: white;
    color: #1082ff;
  }
  .page#main main section#contactUs article div a:hover {
    background-color: #1082ff;
    color: white;
  }
  .page#main main section#contactUs article iframe,
  .page#main main section#contactUs article video {
    height: 100%;
    width: auto;
  }
  .page#main main section#partners {
    padding: 64px max(138px, 10vw);
    gap: 72px;
  }
  .page#main main section#partners article.window {
    width: 100%;
    overflow: hidden;
  }
  .page#main main section#partners article.window .content,
  .page#main main section#partners article.window .tail {
    width: max-content;
    overflow: visible;
    display: flex;
    gap: 44px;
  }
  .page#main main section#partners article.window .content {
    position: relative;
    padding-right: 44px;
    animation: scroll-left 40s linear infinite;
  }
  .page#main main section#partners article.window .tail {
    position: absolute;
    left: 100%;
  }
  .productCommon#banner {
    height: 400px;
    gap: 40px;
    padding: 0 max(138px, 10vw);
  }
  .productCommon#banner p {
    font-size: 32px;
  }
  .productCommon#banner a {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 168px;
    height: 54px;
    border: 1px solid #1082ff;
    border-radius: 100vh;
    font-family: Inter;
    font-size: 16px;
    font-weight: 700;
    background-color: white;
    color: #1082ff;
    border-color: white;
    background-color: gray;
    color: white;
  }
  .productCommon#banner a:hover {
    background-color: #1082ff;
    color: white;
  }
  .productCommon#banner a:hover {
    background-color: white;
    color: black;
  }
  .productCommon#info {
    gap: 44px;
    padding: 64px max(138px, 10vw);
  }
  .productCommon#info .headline {
    gap: 20px;
  }
  .productCommon#info .headline img.logo {
    height: 40px;
  }
  .productCommon#info .headline .divider {
    width: 150px;
  }
  .productCommon#info .headline .description {
    font-size: 20px;
  }
  .productCommon#info article {
    flex-direction: row;
    align-items: flex-start;
    gap: 24px;
  }
  .productCommon#info article .productWrapper {
    width: 30%;
  }
  .productCommon#info article .productWrapper img {
    width: 100%;
    object-fit: contain;
  }
  .productCommon#info article div {
    gap: 24px;
  }
  .productCommon#info article div p {
    font-size: 16px;
  }
  .productCommon.pointHeadline {
    gap: 20px;
  }
  .productCommon.pointHeadline .chip {
    padding: 4px 18px;
    font-size: 20px;
  }
  .productCommon.pointHeadline .title {
    font-size: 32px;
  }
  .productCommon#viewMore {
    flex-direction: row;
    gap: 24px;
    padding: 64px max(138px, 10vw);
  }
  .productCommon#viewMore .general {
    gap: 0;
  }
  .productCommon#viewMore .general p {
    font-size: 32px;
  }
  .productCommon#viewMore .cardWrapper {
    gap: 24px;
    flex-direction: row;
    align-items: flex-start;
  }
  .productCommon#viewMore .cardWrapper .viewMoreCard {
    border-radius: 12px;
    width: 260px;
    padding: 32px;
    gap: 28px;
  }
  .productCommon#viewMore .cardWrapper .viewMoreCard img.logo {
    height: 32px;
  }
  .productCommon#viewMore .cardWrapper .viewMoreCard button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 152px;
    height: 48px;
    border: 1px solid #1082ff;
    border-radius: 100vh;
    font-family: Inter;
    font-size: 16px;
    font-weight: 700;
    background-color: white;
    color: #1082ff;
    font-size: 14px;
  }
  .productCommon#viewMore .cardWrapper .viewMoreCard button:hover {
    background-color: #1082ff;
    color: white;
  }
  #medistep section.points,
  #medistepM section.points {
    padding: 64px max(138px, 10vw);
    gap: 80px;
  }
  #medistep section.points .point,
  #medistepM section.points .point {
    gap: 64px;
  }
  #medistep section.points .point:nth-child(1) div.content,
  #medistepM section.points .point:nth-child(1) div.content {
    flex-direction: row;
    gap: 24px;
  }
  #medistep section.points .point:nth-child(2) img,
  #medistepM section.points .point:nth-child(2) img {
    height: 380px;
  }
  #medistep section.points .point:nth-child(3) img,
  #medistepM section.points .point:nth-child(3) img {
    height: 420px;
  }
  .page#aboutCompany #pageContent {
    padding: 0px max(138px, 10vw) 64px max(138px, 10vw);
    gap: 72px;
  }
  .page#aboutCompany #pageContent section {
    flex-direction: row;
    gap: 16px;
  }
  .page#aboutCompany #pageContent section aside {
    width: 280px;
    font-size: 24px;
  }
  .page#aboutCompany #pageContent section#background article {
    gap: 20px;
  }
  .page#aboutCompany #pageContent section#background article p {
    font-size: 16px;
  }
  .page#aboutCompany #pageContent section#background article figure {
    justify-content: flex-start;
    gap: 24px;
    padding: 24px 0;
  }
  .page#aboutCompany #pageContent section#background article .refCard {
    width: 240px;
    min-height: 240px;
  }
  .page#aboutCompany #pageContent section#background article .refCard .cardContent {
    padding: 24px 24px;
    gap: 16px;
  }
  .page#aboutCompany #pageContent section#background article .refCard .cardContent .title {
    font-size: 18px;
  }
  .page#aboutCompany #pageContent section#background article .refCard .cardContent .subTitle {
    font-size: 14px;
  }
  .page#aboutCompany #pageContent section#background article .refCard .cardContent div {
    width: 44px;
    height: 44px;
  }
  .page#aboutCompany #pageContent section#background article .refCard .cardContent div svg {
    width: 18px;
  }
  .page#aboutCompany #pageContent section#missionVision article {
    gap: 25px;
  }
  .page#aboutCompany #pageContent section#missionVision article .visionCard {
    padding: 32px 24px;
    width: 480px;
    min-height: 140px;
  }
  .page#aboutCompany #pageContent section#missionVision article .visionCard p {
    font-size: 16px;
  }
  .page#aboutCompany #pageContent section#history article {
    gap: 48px;
  }
  .page#aboutCompany #pageContent section#history .historyOfYear {
    gap: 48px;
  }
  .page#aboutCompany #pageContent section#history .historyOfYear .year {
    font-size: 18px;
    line-height: 32px;
  }
  .page#aboutCompany #pageContent section#history .historyOfMonth {
    gap: 14px;
  }
  .page#aboutCompany #pageContent section#history .historyOfMonth p,
  .page#aboutCompany #pageContent section#history .historyOfMonth li {
    font-size: 16px;
    line-height: 32px;
  }
  .page#aboutCompany #pageContent section#members article {
    justify-content: flex-start;
    gap: 32px 36px;
  }
  .page#aboutCompany #pageContent section#members article .memberCard {
    width: 250px;
    min-height: 250px;
    padding: 28px 30px;
    gap: 14px;
  }
  .page#aboutCompany #pageContent section#members article .memberCard .name {
    font-size: 20px;
  }
  .page#aboutCompany #pageContent section#members article .memberCard .description {
    gap: 4px;
  }
  .page#aboutCompany #pageContent section#members article .memberCard .description p {
    font-size: 16px;
  }
  .page#aboutCompany #pageContent section#resource article .fileDownloader {
    gap: 24px;
    padding: 16px 0;
  }
  .page#aboutCompany #pageContent section#resource article .fileDownloader p {
    font-size: 18px;
  }
  .page#aboutCompany #pageContent section#resource article .fileDownloader button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 150px;
    height: 48px;
    border: 1px solid #1082ff;
    border-radius: 100vh;
    font-family: Inter;
    font-size: 16px;
    font-weight: 700;
    background-color: white;
    color: #1082ff;
    min-width: 150px;
    min-height: 48px;
  }
  .page#aboutCompany #pageContent section#resource article .fileDownloader button:hover {
    background-color: #1082ff;
    color: white;
  }
  .page#aboutUsage #pageContent #banners .usageBanner {
    padding: 80px max(138px, 10vw);
    gap: 24px;
  }
  .page#aboutUsage #pageContent #banners .usageBanner .title {
    font-size: 32px;
  }
  .page#aboutUsage #pageContent #banners .usageBanner .descriptionWrapper {
    gap: 16px;
  }
  .page#aboutUsage #pageContent #banners .usageBanner .descriptionWrapper p {
    font-size: 18px;
  }
  .page#aboutUsage #pageContent #banners .usageBanner .hashtagWrapper {
    margin-top: 20px;
    gap: 16px;
  }
  .page#aboutUsage #pageContent #banners .usageBanner .hashtagWrapper p {
    padding: 8px 20px;
    font-size: 14px;
  }
  .page#aboutUsage #pageContent #poc {
    gap: 54px;
    padding: 64px max(138px, 10vw);
  }
  .page#aboutUsage #pageContent #poc #bar {
    height: 72px;
  }
  .page#aboutUsage #pageContent #poc article.title {
    gap: 10px;
  }
  .page#aboutUsage #pageContent #poc article.title p:nth-of-type(1) {
    font-size: 32px;
  }
  .page#aboutUsage #pageContent #poc article.title p:nth-of-type(2) {
    font-size: 20px;
  }
  .page#aboutUsage #pageContent #poc article.window {
    width: 100%;
    overflow: hidden;
  }
  .page#aboutUsage #pageContent #poc article.window .content,
  .page#aboutUsage #pageContent #poc article.window .tail {
    width: max-content;
    overflow: visible;
    display: flex;
    gap: 50px;
  }
  .page#aboutUsage #pageContent #poc article.window .content {
    position: relative;
    padding-right: 50px;
    animation: scroll-left 60s linear infinite;
  }
  .page#aboutUsage #pageContent #poc article.window .tail {
    position: absolute;
    left: 100%;
  }
  .page#aboutUsage #pageContent #poc article.window .pocCard {
    gap: 12px;
  }
  .page#aboutUsage #pageContent #poc article.window .pocCard img {
    width: 400px;
    height: 400px;
  }
  .page#aboutUsage #pageContent #poc article.window .pocCard p {
    font-size: 14px;
  }
  .page#aboutUsage #pageContent #inquiry {
    gap: 48px;
    padding: 64px max(138px, 10vw);
  }
  .page#aboutUsage #pageContent #inquiry div {
    gap: 24px;
  }
  .page#aboutUsage #pageContent #inquiry div p:nth-of-type(1) {
    font-size: 32px;
  }
  .page#aboutUsage #pageContent #inquiry div p:nth-of-type(2) {
    font-size: 18px;
  }
  .page#aboutUsage #pageContent #inquiry a {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 200px;
    height: 56px;
    border: 1px solid #1082ff;
    border-radius: 100vh;
    font-family: Inter;
    font-size: 16px;
    font-weight: 700;
    background-color: white;
    color: #1082ff;
  }
  .page#aboutUsage #pageContent #inquiry a:hover {
    background-color: #1082ff;
    color: white;
  }
  .page#aboutNews #pageContent section#newsCardGroup {
    gap: 48px 56px;
    padding: 0 max(138px, 10vw) 64px max(138px, 10vw);
  }
  .page#aboutNews #pageContent section#newsCardGroup .newsCard {
    width: 300px;
    min-height: 380px;
    border-radius: 10px;
  }
  .page#aboutNews #pageContent section#newsCardGroup .newsCard:hover {
    transform: translateY(-16px);
  }
  .page#aboutNews #pageContent section#newsCardGroup .newsCard .cardContent > div {
    padding: 20px 32px;
    gap: 20px;
  }
  .page#aboutNews #pageContent section#newsCardGroup .newsCard .cardContent > div p {
    font-size: 16px;
  }
}
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: Inter, Helvetica, Arial, sans-serif;
  /* prevent drag or selecting*/
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  /* hide scrollbar */
}
*::-webkit-scrollbar {
  display: none;
  width: 0;
  background: transparent;
}

a {
  text-decoration: none;
}

p {
  line-height: 1.5;
}

button {
  text-transform: none;
}

