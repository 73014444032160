@import "Color";
@import "Mixin";

header {
  position: sticky;
  top: 0;
  z-index: 1000;

  display: flex;
  justify-content: space-between;
  align-items: center;

  background-color: white;
  border-bottom: 1px solid $line;

  .leading {
    button {
      width: max-content;
      justify-content: flex-start !important;
      align-items: flex-start !important;
    }
  }

  button {
    min-width: 0 !important;

    svg {
      color: black;
    }
  }

  nav {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .dropdownBtn {
      cursor: pointer;

      .mainPage {
        display: flex;
        justify-content: center;
        align-items: center;

        color: #444;
        text-align: center;

        border-top: 3px solid transparent;
        border-bottom: 3px solid transparent;

        font-family: Inter;
        font-weight: 700;

        height: 100%;
      }

      .subPages {
        max-height: 0px;
        overflow: hidden;
        @include general-transition;

        background: white;
        box-shadow: 0px 20px 30px 0px rgba(0, 0, 0, 0.25);

        button {
          display: flex;
          justify-content: center;
          align-items: center;

          color: #444;
          background-color: white;
          border: 1px solid $line;

          font-family: Inter;
          font-weight: 700;

          &:hover {
            border-color: $priamry500;
            background-color: $priamry500;
            color: white;
          }
        }
      }

      &:hover {
        .mainPage {
          border-bottom: 3px solid $priamry500;
        }

        .subPages {
          max-height: 100vh;
        }
      }
    }
  }
}

footer {
  background-color: black;
  color: white;

  display: flex;
  flex-direction: column;
  align-items: flex-start;

  img {
    filter: brightness(0) invert(1);
  }

  section {
    width: 100%;
    display: flex;
    flex-direction: column;

    article {
      display: flex;
      flex-direction: column;

      p {
        font-family: Inter;
        font-weight: 400;
        line-height: 1.6;

        &#copyright {
          color: #c7c7c7;
        }
      }
    }
  }
}

#pageTitle {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  p {
    color: $text;

    font-family: Inter;
    font-weight: 700;
  }

  #divider {
    height: 1px;
    background-color: $priamry500;
  }
}

.viewMore {
  color: white;

  p {
    font-family: Inter;
    font-weight: 300;
    text-decoration: underline;
  }
}

button#scrollToTop {
  position: fixed;

  aspect-ratio: 1;
  min-width: auto;
  min-height: auto;

  background: $priamry500;
  @include clip-capsule();

  display: flex;
  justify-content: center;
  align-items: center;
}

#languageSwitchButton {
  display: flex;
  align-items: center;
  color: #444;

  .buttonWrapper {
    display: flex;
    align-items: center;

    button {
      color: #444;

      font-family: Inter;

      &.selected {
        font-weight: 700;
      }
    }

    .divider {
      width: 2px;
      background-color: #444;
    }
  }
}
