@import "Color";
@import "Mixin";

@keyframes scroll-left {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}

@mixin spring-pop($translate: 10px) {
  animation: spring-pop 0.2s linear;

  @keyframes spring-pop {
    0% {
      transform: translateY(0);
    }
    60% {
      transform: translateY(#{$translate});
    }
    70% {
      transform: translateY(calc(1.5 * #{$translate}));
    }
    100% {
      transform: translateY(calc(0.75 * #{$translate}));
    }
  }
}
