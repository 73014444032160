@import "Color";
@import "Mixin";

.page#aboutNews {
  #pageContent {
    section {
      &#newsCardGroup {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;

        .newsCard {
          @include general-transition();

          overflow: hidden;
          border: 1px solid #444;
          background-color: #444;
          box-shadow: none;

          &:hover {
            border-color: $priamry500;
            background-color: $priamry500;
            box-shadow: 0px 0px 20px 0px rgba(16, 130, 255, 0.35);
          }

          .cardContent {
            display: flex;
            flex-direction: column;

            height: 100%;

            figure {
              width: 100%;
              aspect-ratio: 5/4;
              overflow: hidden;

              img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                transform: scale(1.01);
              }
            }

            > div {
              flex: 1;

              color: white;
              display: flex;
              flex-direction: column;

              p {
                flex: 1;

                font-family: Inter;
                font-weight: 400;
                line-height: 1.6;
              }
            }
          }
        }
      }
    }
  }
}
