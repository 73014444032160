@import "Color";
@import "Mixin";

.page#main {
  // MARK: Banner
  #mainBanner {
    position: relative;

    .banner {
      width: 100%;
      height: auto;
      aspect-ratio: 4/1;
    }

    #navigator {
      position: absolute;
      z-index: 100;
      bottom: 0;
      right: 0;

      display: flex;
      align-items: center;
      color: white;
      background-color: rgba(0, 0, 0, 0.5);
      @include clip-capsule();

      button {
        min-width: 0 !important;
        color: white;
      }

      p {
        color: white;
        font-family: Inter;
        font-weight: 500;
        line-height: normal;
        font-variant-numeric: tabular-nums;
      }
    }
  }

  // MARK: Sections
  main section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .sectionTitle {
      color: $priamry500;

      font-family: Gotham;
      font-weight: 900;
      line-height: 1;
    }

    &#landing {
      position: relative;

      background: url("/public/Main/landing.png");
      background-size: cover;
      background-position: right;

      article {
        width: 100%;

        display: flex;
        flex-direction: column;

        color: black;

        div {
          display: flex;
          flex-direction: column;

          p {
            font-family: Inter;
            font-weight: 700;
          }

          #divider {
            height: 1px;
            background-color: $priamry500;
          }
        }

        .headline {
          font-family: Gotham;
          font-weight: 700;
          line-height: 1;
          letter-spacing: -1px;
        }

        .description {
          font-family: Inter;
          font-weight: 500;
        }
      }

      .scrollDown {
        position: absolute;

        cursor: pointer;

        display: flex;
        flex-direction: column;
        align-items: center;

        color: $priamry500;

        p {
          font-family: Inter;
          font-weight: 700;
        }
      }
    }

    &#steps {
      img {
        width: 90%;
      }
    }

    &#productLineUp {
      article {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        .productCard {
          display: flex;
          flex-direction: column;
          align-items: center;

          img {
            width: 100%;
            object-fit: contain;

            &.product {
              width: 90%;
              object-position: center;
              aspect-ratio: 1;
            }
          }

          .thumbnail {
            position: relative;

            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            width: 100%;
            aspect-ratio: 1;

            &:hover {
              .children {
                opacity: 1;
              }
            }

            .children {
              @include general-transition($duration: 0.3s);
              opacity: 0;

              position: absolute;
              top: 0;

              width: 100%;
              height: 100%;

              display: flex;
              justify-content: center;
              align-items: center;

              backdrop-filter: blur(6px);

              p {
                color: black;
                text-align: center;
                font-family: Inter;

                span {
                  font-weight: 700;
                }
              }
            }
          }
        }
      }
    }

    &#solution {
      article {
        width: 100%;

        background: url("/public/Main/our-solution.png");
        background-position: right bottom;
        background-repeat: no-repeat;

        > div {
          width: fit-content;
          display: flex;
          flex-direction: column;

          color: black;

          backdrop-filter: blur(5px);

          p {
            font-family: Inter;
            font-weight: 400;

            &.headline {
              font-weight: 700;
            }
          }

          .viewPDF {
            position: relative;
            width: fit-content;

            display: flex;
            flex-direction: row;
            align-items: center;

            color: $priamry500;

            &::after {
              content: "";
              width: 100%;
              height: 1px;
              position: absolute;
              bottom: 2px;
              background-color: $priamry500;
            }

            &:hover {
              color: #0067d8;

              &::after {
                background-color: #0067d8;
              }
            }

            p {
              font-family: Inter;
              font-weight: 600;
            }
          }
        }
      }
    }

    &#technology {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;

      .card {
        overflow: hidden;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        &.tech {
          position: relative;

          .side {
            perspective: 1000px;
            transform-style: preserve-3d;
            @include general-transition();
            backface-visibility: hidden;

            position: absolute;
            top: 0;
            left: 0;

            width: 100%;
            height: 100%;

            &.front {
              transform: rotateY(0deg);
            }
            &.back {
              transform: rotateY(180deg);
            }
          }

          &:hover {
            .front {
              transform: rotateY(180deg);
            }
            .back {
              transform: rotateY(0deg);
            }
          }

          .front {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: flex-end;

            color: white;

            p {
              font-family: Gotham;
              font-weight: 700;
              line-height: 1.15;
            }

            .circle {
              background-color: white;
              @include clip-capsule();
            }
          }

          .back {
            display: flex;
            flex-direction: column;
            align-items: center;

            background-color: #f3f3f3;

            p {
              color: black;
              text-align: center;

              font-family: Inter;

              &:nth-of-type(1) {
                font-weight: 700;
                line-height: 1.3;
              }

              &:nth-of-type(2) {
                flex: 1;
                display: flex;
                align-items: center;

                font-weight: 400;
              }
            }

            .divider {
              height: 1px;
              min-height: 1px;
              background-color: $priamry500;
            }
          }
        }
      }
    }

    &#news {
      article {
        width: 100%;

        display: flex;
        flex-direction: column;
        align-items: center;

        background-color: $priamry500;

        .sectionTitle {
          color: white;
        }

        .swiper {
          width: 100%;

          --swiper-theme-color: white;
          --swiper-pagination-bottom: auto;

          .swiper-button-prev,
          .swiper-button-next {
            transform: scaleX(0.7);
          }
        }

        .newsSlide {
          width: 100%;
          height: 100%;

          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-end;

          color: white;
          background-color: aqua;

          p {
            font-family: Inter;

            &.title {
              width: 90%;

              font-weight: 500;
            }

            &.caption {
              font-weight: 300;
            }
          }
        }
      }
    }

    &#contactUs {
      background-color: $primary200;

      article {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        div {
          width: 100%;
          display: flex;
          flex-direction: column;

          p {
            color: black;

            font-family: Inter;
            font-weight: 700;
          }

          a {
            @include button-capsule($width: 186px, $height: 56px);
          }
        }

        iframe,
        video {
          height: 100%;
          aspect-ratio: 16/9;
        }
      }
    }

    &#partners {
      article {
        &.window {
          .partnerCard {
            display: flex;
            align-items: center;

            img {
              transform: scale(0.85);
            }
          }
        }
      }
    }
  }
}
