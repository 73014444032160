@charset "utf-8";

@font-face {
  font-family: "Inter";
  font-weight: 900;
  src: url("./Inter-Black.eot?#iefix") format("embedded-opentype"),
    url("./Inter-Black.woff") format("woff"),
    url("./Inter-Black.woff2") format("woff2"),
    url("./Inter-Black.ttf") format("truetype");
}

@font-face {
  font-family: "Inter";
  font-weight: 800;
  src: url("./Inter-ExtraBold.eot?#iefix") format("embedded-opentype"),
    url("./Inter-ExtraBold.woff") format("woff"),
    url("./Inter-ExtraBold.woff2") format("woff2"),
    url("./Inter-ExtraBold.ttf") format("truetype");
}

@font-face {
  font-family: "Inter";
  font-weight: 700;
  src: url("./Inter-Bold.eot?#iefix") format("embedded-opentype"),
    url("./Inter-Bold.woff") format("woff"),
    url("./Inter-Bold.woff2") format("woff2"),
    url("./Inter-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Inter";
  font-weight: 600;
  src: url("./Inter-SemiBold.eot?#iefix") format("embedded-opentype"),
    url("./Inter-SemiBold.woff") format("woff"),
    url("./Inter-SemiBold.woff2") format("woff2"),
    url("./Inter-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: "Inter";
  font-weight: 500;
  src: url("./Inter-Medium.eot?#iefix") format("embedded-opentype"),
    url("./Inter-Medium.woff") format("woff"),
    url("./Inter-Medium.woff2") format("woff2"),
    url("./Inter-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Inter";
  font-weight: 400;
  src: url("./Inter-Regular.eot?#iefix") format("embedded-opentype"),
    url("./Inter-Regular.woff") format("woff"),
    url("./Inter-Regular.woff2") format("woff2"),
    url("./Inter-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Inter";
  font-weight: 300;
  src: url("./Inter-Light.eot?#iefix") format("embedded-opentype"),
    url("./Inter-Light.woff") format("woff"),
    url("./Inter-Light.woff2") format("woff2"),
    url("./Inter-Light.ttf") format("truetype");
}

@font-face {
  font-family: "Inter";
  font-weight: 200;
  src: url("./Inter-ExtraLight.eot?#iefix") format("embedded-opentype"),
    url("./Inter-ExtraLight.woff") format("woff"),
    url("./Inter-ExtraLight.woff2") format("woff2"),
    url("./Inter-ExtraLight.ttf") format("truetype");
}

@font-face {
  font-family: "Inter";
  font-weight: 100;
  src: url("./Inter-Thin.eot?#iefix") format("embedded-opentype"),
    url("./Inter-Thin.woff") format("woff"),
    url("./Inter-Thin.woff2") format("woff2"),
    url("./Inter-Thin.ttf") format("truetype");
}

@font-face {
  font-family: "Pretendard";
  font-weight: 900;
  src: url("./Pretendard-Black.eot?#iefix") format("embedded-opentype"),
    url("./Pretendard-Black.woff") format("woff"),
    url("./Pretendard-Black.woff2") format("woff2"),
    url("./Pretendard-Black.ttf") format("truetype");
}

@font-face {
  font-family: "Pretendard";
  font-weight: 800;
  src: url("./Pretendard-ExtraBold.eot?#iefix") format("embedded-opentype"),
    url("./Pretendard-ExtraBold.woff") format("woff"),
    url("./Pretendard-ExtraBold.woff2") format("woff2"),
    url("./Pretendard-ExtraBold.ttf") format("truetype");
}

@font-face {
  font-family: "Pretendard";
  font-weight: 700;
  src: url("./Pretendard-Bold.eot?#iefix") format("embedded-opentype"),
    url("./Pretendard-Bold.woff") format("woff"),
    url("./Pretendard-Bold.woff2") format("woff2"),
    url("./Pretendard-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Pretendard";
  font-weight: 600;
  src: url("./Pretendard-SemiBold.eot?#iefix") format("embedded-opentype"),
    url("./Pretendard-SemiBold.woff") format("woff"),
    url("./Pretendard-SemiBold.woff2") format("woff2"),
    url("./Pretendard-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: "Pretendard";
  font-weight: 500;
  src: url("./Pretendard-Medium.eot?#iefix") format("embedded-opentype"),
    url("./Pretendard-Medium.woff") format("woff"),
    url("./Pretendard-Medium.woff2") format("woff2"),
    url("./Pretendard-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Pretendard";
  font-weight: 400;
  src: url("./Pretendard-Regular.eot?#iefix") format("embedded-opentype"),
    url("./Pretendard-Regular.woff") format("woff"),
    url("./Pretendard-Regular.woff2") format("woff2"),
    url("./Pretendard-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Pretendard";
  font-weight: 300;
  src: url("./Pretendard-Light.eot?#iefix") format("embedded-opentype"),
    url("./Pretendard-Light.woff") format("woff"),
    url("./Pretendard-Light.woff2") format("woff2"),
    url("./Pretendard-Light.ttf") format("truetype");
}

@font-face {
  font-family: "Pretendard";
  font-weight: 200;
  src: url("./Pretendard-ExtraLight.eot?#iefix") format("embedded-opentype"),
    url("./Pretendard-ExtraLight.woff") format("woff"),
    url("./Pretendard-ExtraLight.woff2") format("woff2"),
    url("./Pretendard-ExtraLight.ttf") format("truetype");
}

@font-face {
  font-family: "Pretendard";
  font-weight: 100;
  src: url("./Pretendard-Thin.eot?#iefix") format("embedded-opentype"),
    url("./Pretendard-Thin.woff") format("woff"),
    url("./Pretendard-Thin.ttf") format("truetype");
}

@font-face {
  font-family: "Gotham";
  font-weight: 900;
  src: url("./Gotham-Black.otf") format("opentype");
}

@font-face {
  font-family: "Gotham";
  font-weight: 700;
  src: url("./Gotham-Bold.otf") format("opentype");
}
