@mixin general-transition($duration: 0.5s) {
  transition: all $duration ease;
}

@mixin clip-capsule() {
  border-radius: 100vh;
}

@mixin button-capsule($width: 150px, $height: 45px) {
  display: flex;
  justify-content: center;
  align-items: center;

  width: $width;
  height: $height;

  border: 1px solid $priamry500;
  @include clip-capsule();

  font-family: Inter;
  font-size: 16px;
  font-weight: 700;

  background-color: white;
  color: $priamry500;

  &:hover {
    background-color: $priamry500;
    color: white;
  }
}

@mixin infinite-looper($contentGap: 50px, $duration: 60s) {
  width: 100%;
  overflow: hidden;

  .content,
  .tail {
    width: max-content;
    overflow: visible;

    display: flex;
    gap: $contentGap;
  }

  .content {
    position: relative;
    padding-right: $contentGap;

    animation: scroll-left $duration linear infinite;
  }

  .tail {
    position: absolute;
    left: 100%;
  }
}
