@import "Color";
@import "Mixin";

// Product Commons
.productCommon {
  &#banner {
    display: flex;
    flex-direction: column;
    justify-content: center;

    background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
      url(/public/mainCover_bg.jpeg) no-repeat center center;
    background-size: cover;

    p {
      color: white;

      font-family: Inter;
      font-weight: 700;
    }
  }

  &#info {
    display: flex;
    flex-direction: column;

    .headline {
      display: flex;
      flex-direction: column;

      img.logo {
        object-fit: contain;
        object-position: left;
      }

      .divider {
        height: 2px;
        background-color: $priamry500;
      }

      .description {
        color: #939393;

        font-family: Inter;
        font-weight: 600;
      }
    }

    article {
      display: flex;

      .productWrapper {
        width: 30%;

        img {
          width: 100%;
          object-fit: contain;
        }
      }

      div {
        &.children {
          flex: 1;
        }

        display: flex;
        flex-direction: column;

        p {
          color: #939393;

          font-family: Inter;

          span {
            color: $priamry500;
            font-weight: 700;
          }
        }
      }
    }
  }

  &.pointHeadline {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .chip {
      background-color: $priamry500;
      color: white;
      @include clip-capsule();

      font-family: Inter;
      font-weight: 700;
    }

    .title {
      color: black;

      text-align: center;

      font-family: Inter;
      font-weight: 700;
    }
  }

  &#viewMore {
    display: flex;
    justify-content: space-between;
    background-color: $primary200;

    .general {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      p {
        color: black;

        font-family: Inter;
        font-weight: 700;
      }
    }

    .cardWrapper {
      display: flex;

      .viewMoreCard {
        display: flex;
        flex-direction: column;
        align-items: center;

        background-color: white;

        aspect-ratio: 1;

        img {
          object-fit: contain;

          &.product {
            width: 90%;
            aspect-ratio: 1;
          }
        }
      }
    }
  }
}

// Pages
#medistep,
#medistepM {
  section {
    overflow: hidden;

    &.points {
      width: 100%;

      display: flex;
      flex-direction: column;
      align-items: center;

      .point {
        width: 100%;

        display: flex;
        flex-direction: column;
        align-items: center;

        &:nth-child(1) {
          div {
            width: 100%;
            display: flex;
            align-items: center;

            article {
              flex: 1;

              display: flex;
              justify-content: center;
              align-items: center;

              > * {
                width: 100%;
                object-fit: contain;
              }
            }
          }
        }

        &:nth-child(2) {
          img {
            object-fit: contain;
          }
        }

        &:nth-child(3) {
          img {
            object-fit: contain;
          }
        }
      }
    }
  }
}
