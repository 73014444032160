@import "Color";
@import "Mixin";

.page#aboutUsage {
  #pageContent {
    display: flex;
    flex-direction: column;

    #banners {
      .usageBanner {
        display: flex;
        flex-direction: column;
        justify-content: center;

        color: white;

        .title {
          font-family: Gotham;
          font-weight: 900;
        }

        .descriptionWrapper {
          display: flex;
          flex-direction: column;

          p {
            font-family: Inter;
            font-weight: 300;
            line-height: 1.5;
          }
        }

        .hashtagWrapper {
          display: flex;
          flex-direction: row;
          align-items: center;
          flex-wrap: wrap;

          p {
            display: flex;
            justify-content: center;
            align-items: center;

            @include clip-capsule();

            background-color: #d9d9d9;
            color: #444;

            font-family: Inter;
            font-weight: 700;
          }
        }
      }
    }

    #poc {
      display: flex;
      flex-direction: column;
      align-items: center;

      #bar {
        width: 1px;

        background-color: $priamry500;
      }

      article {
        &.title {
          display: flex;
          flex-direction: column;
          align-items: center;

          p {
            font-family: Inter;
            text-align: center;

            &:nth-of-type(1) {
              color: $priamry500;
              font-weight: 700;
            }
            &:nth-of-type(2) {
              color: $text;
              font-weight: 400;
            }
          }
        }

        &.window {
          .pocCard {
            display: flex;
            flex-direction: column;
            align-items: center;

            img {
              object-fit: cover;
            }

            p {
              color: $text;

              font-family: Inter;
              font-weight: 500;
            }
          }
        }
      }
    }

    #inquiry {
      display: flex;
      flex-direction: column;
      align-items: center;

      background-color: #ddd;

      div {
        display: flex;
        flex-direction: column;
        align-items: center;

        p {
          color: $text;
          font-family: Inter;
          text-align: center;

          &:nth-of-type(1) {
            font-weight: 700;
          }

          &:nth-of-type(2) {
            font-weight: 500;
          }
        }
      }
    }
  }
}
