@import "Animation";
@import "Color";
@import "Mixin";
@import "Layout";

@import "MainPage";

@import "AboutCompanyPage";
@import "AboutUsagePage";
@import "AboutNewsPage";

@import "ProductPage";

@import "SizeMobile";
@import "SizeDesktop";

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: Inter, Helvetica, Arial, sans-serif;

  /* prevent drag or selecting*/
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  /* hide scrollbar */
  &::-webkit-scrollbar {
    display: none;
    width: 0;
    background: transparent;
  }
}

// #root {
//   min-width: 1280px;
// }

a {
  text-decoration: none;
}

p {
  line-height: 1.5;
}

button {
  text-transform: none;
}
